<template>
  <div class="fill-height">
    <!-- <v-tabs v-model="selectedTab" center-active centered grow icons-and-text>
      <v-tab key="rtc">
        <span v-text="$t('rtc')" />
        <v-icon v-text="'mdi-phone'" />
      </v-tab> -->

    <!-- <v-tab key="poker">
        <span v-text="$t('poker')" />
        <v-icon v-text="'mdi-cards-spade'" />
      </v-tab> -->
    <!-- </v-tabs> -->

    <!-- <v-tabs-items v-model="selectedTab" class="fill-height">
      <v-tab-item key="rtc" class="fill-height"> -->
    <v-container fluid fill-height>
      <v-row align="stretch" justify="center">
        <v-col :cols="portrait ? 12 : 6" md="6">
          <video
            id="localVideo"
            :srcObject.prop="localStream"
            playsinline
            autoplay
            muted
            autoPictureInPicture
            class="secondary"
            aria-label="Local video stream"
          />
        </v-col>
        <v-col :cols="portrait ? 12 : 6" md="6">
          <video
            id="remoteVideo"
            :srcObject.prop="remoteStream"
            playsinline
            autoplay
            autoPictureInPicture
            class="secondary"
            aria-label="Remote video stream"
          />
        </v-col>
      </v-row>
    </v-container>
    <!-- </v-tab-item> -->

    <!-- <v-tab-item key="poker" class="fill-height">
        <v-container fluid fill-height class="justify-center">
          <transition-group
            name="list"
            tag="div"
            class="d-flex flex-wrap align-center justify-center align-content-center"
          >
            <poker-card
              v-for="vote in votes"
              :key="vote.avatar"
              :value="vote.value.toString()"
              :visible="showValue"
              :avatar="vote.avatar"
              :is-highest="vote.isHighest"
              :is-lowest="vote.isLowest"
              :value-class="vote.class"
              class="flex-grow-0 flex-shrink-1"
            >
              <template v-if="localStream && showVideoOnCards" v-slot:middle-row>
                <v-responsive :aspect-ratio="1">
                  <v-avatar width="100%" height="100%">
                    <v-responsive :aspect-ratio="1">
                      <video
                        :srcObject.prop="localStream"
                        playsinline
                        autoplay
                        muted
                        :controls="false"
                        :contextmenu="false"
                        controlsList="nodownload"
                        disable-picture-in-picture
                        class="secondary"
                        style="transform: scale(2)"
                      />
                    </v-responsive>
                  </v-avatar>
                </v-responsive>
              </template>
            </poker-card>
          </transition-group>

          <v-row align="center" justify="center" class="mb-4">
            <v-col cols="0" xl="2" />

            <v-col cols="6" xl="2" class="text-center">
              <v-autocomplete
                v-model="myName"
                :items="names"
                :label="$t('player')"
                :aria-label="$t('player')"
                hide-selected
                auto-select-first
              >
                <template v-slot:selection="data">
                  <v-list-item-avatar>
                    <img
                      :src="'/img/avatars/' + data.item + '.jpg'"
                      :alt="data.item + '\'s Avatar'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item" />
                    <v-list-item-subtitle v-text="'Teammitglied'" />
                  </v-list-item-content>
                </template>

                <template v-slot:item="data">
                  <v-list-item-avatar>
                    <img
                      :src="'/img/avatars/' + data.item + '.jpg'"
                      :alt="data.item + '\'s Avatar'"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item" />
                    <v-list-item-subtitle v-text="'Teammitglied'" />
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>

            <v-col cols="6" xl="2" class="text-center">
              <v-tooltip
                v-for="pokerValue in selectableValues"
                :key="pokerValue.label"
                top
                color="#606060"
                open-delay="750"
                close-delay="300"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    class="ma-1"
                    tabindex="0"
                    :accesskey="pokerValue.accesskey ? pokerValue.accesskey : undefined"
                    :aria-label="pokerValue.label"
                    @click="bieten(myName, pokerValue.value)"
                    v-on="on"
                    v-text="pokerValue.label"
                  />
                </template>
                <kbd>Alt</kbd> + <kbd>Umschalt</kbd> + <kbd v-text="pokerValue.accesskey" />
              </v-tooltip>
            </v-col>
            <v-col cols="6" xl="2" class="text-center">
              <v-badge
                :value="!showValue && votes && votes.length"
                :icon="
                  votes && votes.length && votes.every(vote => vote.value)
                    ? 'mdi-check'
                    : 'mdi-exclamation-thick'
                "
                :color="
                  votes && votes.length && votes.every(vote => vote.value) ? 'success' : 'grey'
                "
                overlap
                bottom
              >
                <v-tooltip top color="#606060" open-delay="750" close-delay="300">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      color="primary"
                      tabindex="0"
                      accesskey="W"
                      :aria-label="$t('turn-cards')"
                      @click="turnCards"
                      v-on="on"
                      v-text="$t('turn-cards')"
                    />
                  </template>
                  <kbd>Alt</kbd> + <kbd>Umschalt</kbd> + <kbd>W</kbd>
                </v-tooltip>
              </v-badge>
            </v-col>

            <v-col cols="6" xl="2" class="text-center">
              <v-checkbox
                v-model="showVideoOnCards"
                :label="$t('show-video-on-cards')"
                tabindex="0"
              />
            </v-col>

            <v-col cols="0" xl="2" />
          </v-row>
        </v-container>
      </v-tab-item> -->
    <!-- </v-tabs-items> -->

    <v-app-bar
      app
      bottom
      height="72"
      color="secondary"
      role="navigation"
      aria-label="Action buttons"
    >
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <camera-picker v-model="selectedCamera" @input="replaceLocalStream('video')" />
          <resolution-picker v-model="selectedResolution" @input="replaceLocalStream('video')" />
          <microphone-picker v-model="selectedMicrophone" @input="replaceLocalStream('audio')" />

          <v-tooltip top open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn
                id="readyButton"
                fab
                color="primary"
                :disabled="readyButtonDisabled"
                class="mx-1"
                :class="{ 'heartbeat-animation': !readyButtonDisabled }"
                tabindex="0"
                :aria-label="$t('ready')"
                :aria-disabled="readyButtonDisabled"
                @click="handleReady"
                v-on="on"
              >
                <v-icon :large="!readyButtonDisabled"> mdi-phone-check </v-icon>
              </v-btn>
            </template>
            <span v-text="$t('ready')" />
          </v-tooltip>

          <v-tooltip top open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn
                id="callButton"
                fab
                color="success"
                :disabled="callButtonDisbaled"
                class="mx-1"
                :class="{ 'heartbeat-animation': !callButtonDisbaled }"
                tabindex="0"
                :aria-label="$t('call')"
                :aria-disabled="callButtonDisbaled"
                @click="handleCall"
                v-on="on"
              >
                <v-icon :large="!callButtonDisbaled"> mdi-phone </v-icon>
              </v-btn>
            </template>
            <span v-text="$t('call')" />
          </v-tooltip>

          <v-tooltip top open-delay="400">
            <template v-slot:activator="{ on }">
              <v-btn
                id="hangupButton"
                fab
                color="error"
                :disabled="hangupButtonDisabled"
                class="mx-1"
                :class="{ 'heartbeat-animation': !hangupButtonDisabled }"
                tabindex="0"
                :aria-label="$t('hangup')"
                :aria-disabled="hangupButtonDisabled"
                @click="handleHangup"
                v-on="on"
              >
                <v-icon :large="!hangupButtonDisabled"> mdi-phone-hangup </v-icon>
              </v-btn>
            </template>
            <span v-text="$t('hangup')" />
          </v-tooltip>
        </v-col>
      </v-row>
    </v-app-bar>
  </div>
</template>

<script>
import Router from "../router/index.js";
import { Vote } from "../classes/vote.js";

const CameraPicker = () => import("../components/CameraPicker.vue");
const MicrophonePicker = () => import("../components/MicrophonePicker.vue");
const ResolutionPicker = () => import("../components/ResolutionPicker.vue");
// const PokerCard = () => import("../components/PokerCard.vue");

const signalR = require("@microsoft/signalr");

// Methods in the hub called by the front end
const OFFER = "Offer";
const ANSWER = "Answer";
const CANDIDATE = "Candidate";
const LOG_IN = "LogIn";

// Methods in the front end called by the hub
const ON_NEW_OFFER = "NewOffer";
const ON_NEW_ANSWER = "NewAnswer";
const ON_NEW_CANDIDATE = "NewCandidate";

// Peer connection event names
const TRACK = "track";
const ICE_CANDIDATE = "icecandidate";

export default {
  name: "Jhe",

  components: { CameraPicker, MicrophonePicker, ResolutionPicker }, //, PokerCard

  props: { tab: { type: String, default: "0" } },

  data() {
    return {
      configuration: {
        iceServers: this.$config.iceServers
      },
      localStream: null,
      remoteStream: new MediaStream(),
      rtcSignalling: null,
      pokerSignalling: null,
      peerConnection: null,
      readyButtonDisabled: true,
      callButtonDisbaled: true,
      hangupButtonDisabled: true,
      portrait: false,
      selectedCamera: null,
      selectedMicrophone: null,
      selectedResolution: null,
      votes: [],
      showValue: false,
      showVideoOnCards: false,
      myName: null,
      names: ["ama", "bwa", "dro", "dsc", "jhe", "mbut", "mwi", "sku"]
    };
  },

  computed: {
    selectedTab: {
      get() {
        return parseInt(this.tab);
      },

      set(value) {
        Router.push({ name: "jhe", params: { tab: value.toString() } });
      }
    },

    selectableValues() {
      return [
        { label: this.$t("empty"), value: "", accesskey: "E" },
        { label: "1", value: "1", accesskey: "0" },
        { label: "4", value: "4", accesskey: "2" },
        { label: "8", value: "8", accesskey: "3" },
        { label: "16", value: "16", accesskey: "4" },
        { label: "255", value: "255", accesskey: "8" },
        { label: "☕", value: "☕", accesskey: "P" }
        // { label: "♠", value: "♠", accesskey: "♠", class: "black--text" },
        // { label: "♣", value: "♣", accesskey: "♣", class: "black--text" },
        // { label: "♥", value: "♥", accesskey: "♥", class: "error--text" },
        // { label: "♦", value: "♦", accesskey: "♦", class: "error--text" }
      ];
    }
  },

  /**
   * Initializes a new peer connection and a connection to the
   * hub (signaling channel). Afterwards, logs in to the hub.
   */
  async mounted() {
    const portrait = window.matchMedia("all and (orientation:portrait)");
    portrait.onchange = this.switchOrientation;
    this.switchOrientation(portrait);

    document.onvisibilitychange = this.handleVisibilityChange;
    this.handleVisibilityChange();

    await Promise.all([
      this.establishRtcSignallingConnection(),
      this.establishPokerSignallingConnection()
    ]);

    this.initializePeerConnection();
  },

  methods: {
    handleVisibilityChange() {
      switch (document.visibilityState) {
        case "visible":
          this.handleVisibilityVisible();
          break;
        case "prerender":
        case "hidden":
          this.handleVisibilityHidden();
          break;
        case "unloaded":
          this.handleVisibilityUnloaded();
          break;
      }
    },

    handleVisibilityVisible() {
      console.debug("Browsertab ist aktiv");
    },

    handleVisibilityHidden() {
      console.debug("Browsertab ist nicht aktiv");
    },

    handleVisibilityUnloaded() {
      console.debug("Browsertab wird geschlossen");
    },

    /**
     * Establishes a connection with the hub specified in the config file.
     * If successful, it enables the ready button of the front end after
     * a login to the hub.
     */
    async establishRtcSignallingConnection() {
      this.rtcSignalling = new signalR.HubConnectionBuilder().withUrl(this.$config.hubUrl).build();

      this.rtcSignalling.on(ON_NEW_OFFER, this.processNewOffer);
      this.rtcSignalling.on(ON_NEW_ANSWER, this.processNewAnswer);
      this.rtcSignalling.on(ON_NEW_CANDIDATE, this.processNewCandidate);

      try {
        await this.rtcSignalling.start();

        const userId = "B7A36852-E339-4405-98FA-36A7A0681600";
        const secret = "Geheim";

        await this.propagateLogIn(userId, secret);

        this.readyButtonDisabled = false;
      } catch (error) {
        console.error(error.toString());
      }
    },

    /** Initializes a fresh peer connection with the predefined
     *  event handlers.
     */
    initializePeerConnection() {
      this.peerConnection = new RTCPeerConnection(this.configuration);

      this.peerConnection.addEventListener(TRACK, event => this.addRemoteTrack(event.track));

      this.peerConnection.addEventListener(ICE_CANDIDATE, event => {
        if (event.candidate) this.propagateIceCandidate(event.candidate);
      });
    },

    /**
     * Processes an incoming offer from another peer and sends
     * back an answer.
     * @param {RTCSessionDescriptionInit} offer A new offer to be processed
     */
    async processNewOffer(offer) {
      if (!offer || offer.type !== "offer") throw new Error("The provided offer is invalid.");

      const remoteDescription = new RTCSessionDescription(offer);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      const answer = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(answer);
      await this.propagateAnswer(answer);
    },

    /**
     * Processes an incoming answer from another peer.
     * @param {RTCSessionDescriptionInit} answer A new answer to be processed
     */
    async processNewAnswer(answer) {
      if (answer) {
        const remoteDescription = new RTCSessionDescription(answer);
        await this.peerConnection.setRemoteDescription(remoteDescription);
      }
    },

    /**
     * Processes an incoming ICE candidate from another peer.
     * @param {RTCIceCandidateInit} iceCandidate A new ICE candidate to be processed
     */
    async processNewCandidate(iceCandidate) {
      if (iceCandidate) {
        try {
          await this.peerConnection.addIceCandidate(iceCandidate);
        } catch (e) {
          console.error("Error adding received ice candidate", e);
        }
      }

      return;
    },

    /**
     * Upon a click on the ready button, tries to get user media
     * (audio and video) from this client and displays them locally.
     * Afterwards, Enables the call button.
     */
    async handleReady() {
      this.readyButtonDisabled = true;

      try {
        await this.setLocalStream();
        this.callButtonDisbaled = false;
      } catch (error) {
        console.error(`getUserMedia() error: ${error.name}`);
      }
    },

    /**
     * Requests a new user media stream and adds all of its tracks to peerConnection.
     */
    async setLocalStream() {
      this.localStream = await this.getStream();

      this.localStream
        .getTracks()
        .forEach(track => this.peerConnection.addTrack(track, this.localStream));
    },

    /**
     * Requests a new user media stream and replaces all corresponding tracks
     * from peerConnection with those from the new stream.
     * @param {"video"|"audio"|undefined} kind
     */
    async replaceLocalStream(kind) {
      if (!this.localStream) return;

      // first stop the current tracks to release the device
      this.stopStream(this.localStream, kind);

      let stream = await this.getStream();
      let tracks = this.getTracks(stream, kind);

      if (tracks && tracks.length) {
        await Promise.all(tracks.map(this.replaceTrack));
        this.localStream = stream;
      }
    },

    /**
     * Requests and returns a new user media stream matching the selected constraints.
     * @returns {Promise<MediaStream>}
     */
    async getStream() {
      let constraints = this.getConstraints();
      return navigator.mediaDevices.getUserMedia(constraints);
    },

    /**
     * Returns all tracks from the given media stream which are of the given kind.
     * @param {MediaStream} stream
     * @param {"video"|"audio"|undefined} kind
     * @returns {MediaStreamTrack[]}
     */
    getTracks(stream, kind) {
      if (!stream) return;

      switch (kind) {
        case "video":
          return stream.getVideoTracks();

        case "audio":
          return stream.getAudioTracks();

        default:
          return stream.getTracks();
      }
    },

    /**
     * Returns the constraints for requesting user media.
     * @returns {MediaTrackConstraints}
     */
    getConstraints() {
      const width = this.selectedResolution ? this.selectedResolution.width : 640;
      const height = this.selectedResolution ? this.selectedResolution.height : 480;

      return {
        audio: this.selectedMicrophone
          ? {
              deviceId: this.selectedMicrophone,
              echoCancellation: true
            }
          : {
              echoCancellation: true
            },
        video: this.selectedCamera
          ? {
              deviceId: this.selectedCamera,
              facingMode: "user",
              width,
              height
            }
          : {
              facingMode: "user",
              width,
              height
            }
      };
    },

    /**
     * Replaces the first track from peerConnection which is
     * of the same kind as the given track with that track.
     * @param {MediaStreamTrack} track
     * @returns {Promise<undefined>}
     */
    async replaceTrack(track) {
      let targetSender = this.getSender(track);
      return targetSender ? targetSender.replaceTrack(track) : undefined;
    },

    /**
     * Returns the first sender from peerConnection which is
     * of the same kind as the given track.
     * @param {MediaStreamTrack} track
     * @returns {RTCRtpSender}
     */
    getSender(track) {
      if (!this.peerConnection) return;

      let senders = this.peerConnection.getSenders();

      return senders && senders.length
        ? senders.find(sender => sender.track.kind === track.kind)
        : null;
    },

    /**
     *  Upon a click on the call button, creates an offer for other
     *  peers and sends them via the signaling channel.
     */
    async handleCall() {
      this.callButtonDisbaled = true;
      this.hangupButtonDisabled = false;

      const offer = await this.peerConnection.createOffer();
      await this.peerConnection.setLocalDescription(offer);
      await this.propagateOffer(offer);
    },

    /**
     * Disposes the peer connection.
     */
    handleHangup() {
      // renew remote media stream
      this.stopStream(this.remoteStream);
      this.remoteStream = new MediaStream();

      // dispose local media stream
      this.stopStream(this.localStream);
      this.localStream = null;

      // renew connection
      if (this.peerConnection) this.peerConnection.close();
      this.initializePeerConnection();

      // handle buttons
      this.hangupButtonDisabled = true;
      this.readyButtonDisabled = false;
    },

    /**
     * Stops the given media stream.
     * @param {MediaStream} stream - The stream that shall be stopped
     * @param {"video"|"audio"|undefined} kind - Only stop tracks of this kind
     */
    stopStream(stream, kind) {
      if (stream) this.stopTracks(this.getTracks(stream, kind));
    },

    /**
     * Stops the given media stream tracks.
     * @param {MediaStreamTrack[]} tracks - The tracks that shall be stopped
     */
    stopTracks(tracks) {
      if (tracks && tracks.length) tracks.forEach(track => track.stop());
    },

    /** Adds the given track to the remote stream. */
    addRemoteTrack(track) {
      if (!track) throw new Error("Only valid tracks can be added as remote tracks.");

      this.remoteStream.addTrack(track, this.remoteStream);
    },

    /** Login to the hub.
     *  @param {string} userId GUID for the user
     *  @param {string} secret Secret of the user
     */
    async propagateLogIn(userId, secret) {
      console.debug("propagateLogIn");

      try {
        await this.rtcSignalling.invoke(LOG_IN, userId, secret);
      } catch (error) {
        console.error(error.toString());
      }
    },

    /**
     * Propagates the given ICE candidate using the signaling channel (hub).
     * @param {RTCIceCandidateInit} candidate ICE candidate
     */
    async propagateIceCandidate(candidate) {
      console.debug("propagateIceCandidate");
      if (!candidate) throw new Error("Only valid ICE candidates can be propagated.");

      try {
        await this.rtcSignalling.invoke(CANDIDATE, candidate);
      } catch (error) {
        console.error(error.toString());
      }
    },

    /**
     * Propagates the given offer using the signaling channel (hub).
     * @param {RTCSessionDescriptionInit} offer Offer
     */
    async propagateOffer(offer) {
      console.debug("propagateOffer");
      if (!offer || offer.type !== "offer") throw new Error("The provided offer is invalid.");

      try {
        await this.rtcSignalling.invoke(OFFER, offer);
      } catch (error) {
        console.error(error.toString());
      }
    },

    /**
     * Propagates the given answer using the signaling channel (hub).
     * @param {RTCSessionDescriptionInit} answer Answer
     */
    async propagateAnswer(answer) {
      console.debug("propagateAnswer");
      if (!answer || answer.type !== "answer") throw new Error("The provided answer is invalid.");

      try {
        await this.rtcSignalling.invoke(ANSWER, answer);
      } catch (error) {
        console.error(error.toString());
      }
    },

    async establishPokerSignallingConnection() {
      this.signalingConnection = new signalR.HubConnectionBuilder()
        .withUrl(this.$config.scrumpokerHubUrl)
        .build();

      this.signalingConnection.on("Aufdecken", this.aufdecken);

      this.signalingConnection.on("Zudecken", this.zudecken);

      /** @deprecated */
      this.signalingConnection.on("MischtMit", this.addVote);

      this.signalingConnection.on("BietetEinsatz", this.addVote);

      try {
        await this.signalingConnection.start();
      } catch (error) {
        console.error(error.toString());
      }
    },

    aufdecken() {
      this.showValue = true;
      this.votes.sort(this.compareVotes);
    },

    zudecken() {
      this.showValue = false;
    },

    // bietet(name, value) {
    //   this.addVote(name,value)
    // },

    // /** @deprecated */
    // mischtmit(name) {
    //   this.addVote(name,"")
    // },

    async turnCards() {
      if (!this.votes || !this.votes.length) return;

      if (this.showValue) await this.sendZudeckenCommand();
      else await this.sendAufdeckenCommand();
    },

    async sendAufdeckenCommand() {
      await this.signalingConnection.invoke("Aufdecken");
    },

    async sendZudeckenCommand() {
      await this.signalingConnection.invoke("Zudecken");
    },

    /** @deprecated */
    async mitmischen(name) {
      await this.signalingConnection.invoke("Mitmischen", name);
    },

    async bieten(name, value) {
      await this.signalingConnection.invoke("Bieten", name, value);
    },

    addVote(name, value = "") {
      let vote = this.votes.find(v => v.avatar === name);
      if (vote) vote.value = value;
      else {
        vote = new Vote(name, value);
        this.votes.push(vote);
      }

      vote.class = this.lookupClass(vote.value);

      this.highlightVotes();
      if (this.showValue) this.votes.sort(this.compareVotes);
    },

    lookupClass(value) {
      const vote = this.selectableValues.find(v => v.value == value);
      return vote ? vote.class : undefined;
    },

    highlightVotes() {
      const validVotes = this.votes.filter(v => !Number.isNaN(Number.parseInt(v.value)));
      const maxValue = this.getAggregate(validVotes, Math.max, Number.MIN_VALUE);
      const minValue = this.getAggregate(validVotes, Math.min, Number.MAX_VALUE);

      this.votes.forEach(v => {
        v.isHighest = v.value === maxValue.toString() && maxValue !== minValue;
        v.isLowest = v.value === minValue.toString() && maxValue !== minValue;
      });
    },

    getAggregate(votes, aggregator, seed) {
      return votes.length > 1
        ? votes
            .map(v => Number.parseInt(v.value))
            .reduce((previous, current) => {
              if (Number.isNaN(current)) return previous;
              return aggregator(previous, current);
            }, seed)
        : seed;
    },

    compareVotes(a, b) {
      const aParsedValue = Number.parseInt(a.value);
      const bParsedValue = Number.parseInt(b.value);
      if (Number.isNaN(aParsedValue) && Number.isNaN(bParsedValue)) return 0;
      if (Number.isNaN(bParsedValue) || aParsedValue > bParsedValue) return 1;
      if (Number.isNaN(aParsedValue) || aParsedValue < bParsedValue) return -1;
      return 0;
    },

    /**
     * Reacts to changes in the display orientation.
     * @param {MediaQueryList | MediaQueryListEvent} event
     * @returns {void}
     */
    switchOrientation(event) {
      this.portrait = event.matches;
    }
  }
};
</script>

<style lang="scss" scoped>
.heartbeat-animation {
  animation: 5s infinite heartbeat-animation-keyframes;
}

@keyframes heartbeat-animation-keyframes {
  0%,
  25%,
  55%,
  100% {
    transform: scale(1.1);
  }

  15%,
  40% {
    transform: scale(1);
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 1s;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: translateY(10000px);
}

.list-move {
  transition: transform 1s;
}
</style>

<i18n>
{
  "de": {
    "ready": "Bereit",
    "call": "Anrufen",
    "hangup": "Auflegen",
    "no-selection": "Bitte treffen Sie Ihre Auswahl",
    "turn-cards": "Karten wenden",
    "show-video-on-cards": "Video auf Karten anzeigen",
    "player": "Spieler",
    "empty": "Leer"
  },
  "en": {
    "ready": "Ready",
    "call": "Call",
    "hangup": "Hang up",
    "no-selection": "Please make your selection",
    "turn-cards": "Turn cards",
    "show-video-on-cards": "Show video on cards",
    "player": "Player",
    "empty": "empty"
  }
}
</i18n>
